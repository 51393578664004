import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}
interface CartItem {
  id: number;
  title: string;
  sub_title: string;
  price: number;
  quantity: number;
  product_id: number;
  seller_acc_id: string;
  image_url: string;
  order_management_order_id: number | null;
  status: string | null;
  available_quantity: number;
}

interface Cart {
  id: number;
  account_id: number;
  total_amount: string;
  cart_value: string;
  created_at: string;
  updated_at: string;
  cart_count: number;
  selected_count: number;
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}

export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
        | (object & {
            attributes?: object & {
              name?: string;
            };
          })
        | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

interface ShoppingCart {
  cart_items: CartItem[];
  cart: Cart;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  order_id: number;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: ShoppingCartOrder[];
  orderItems: ShoppingCartOrderItem[];
  cartItemList:CartItem[];
  cartDetails: Cart
  isVisible: boolean;
  isRefreshing: boolean;

  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  isProccedToBuyButtonClicked: boolean;
  isConfirmOrderClicked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId?: string;
  showOrderApiCallId?: string;
  createOrderItemApiCallId?: string;
  deleteOrderItemApiCallId?: string;
  getCartOrdersCallId?: string;
  buyOrderApiCallId: string = "";
  updateOrderQuantityApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      cartItemList:[],
      cartDetails: {
        id: 0,
        account_id: 0,
        total_amount: "",
        cart_value: "",
        created_at: "",
        updated_at: "",
        cart_count: 0,
        selected_count: 0,
      },
      isVisible: false,
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      isProccedToBuyButtonClicked: false,
      isConfirmOrderClicked: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start
    this.getCartOrders();
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
  const messageId = message.id;
  const restAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage);

  if (restAPIResponseMessage === messageId) {
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    if (apiCallId === this.getCartOrdersCallId) {
      if (responseJson && !responseJson.errors) {
        this.handleResponseOrderItems(responseJson)
      }
    }
    if (apiCallId === this.deleteOrderItemApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.getCartOrders();
      } 
    }
    if (apiCallId === this.buyOrderApiCallId) {
      if (responseJson &&!responseJson.errors) {
        this.setState({ isProccedToBuyButtonClicked: false, isConfirmOrderClicked: true, loading: false });
      }
    }
    if (apiCallId === this.updateOrderQuantityApiCallId) {
      if (responseJson &&!responseJson.errors) {
        this.getCartOrders();
      }
    }
  } 
    // Customizable Area End
  };

  // Customizable Area Start
  isNumberNull(number_value?: number) {
    return (
      number_value === null ||
      Number.isNaN(number_value) ||
      number_value === undefined
    );
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
  };

  navigateToAddMoreItems = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Filteritems");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };



  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };

  setModal = (item: {
    post: {
      id: number;
      name: string;
      description: string;
      price: number;
      currency: string;
      category_id: number;
      created_at: string;
      updated_at: string;
      account_id: number;
    };
  }) => {
    this.setState({
      id: item.post?.id,
      name: item.post?.name,
      description: item.post?.description,
      price: item.post?.price,
      currency: item.post?.currency,
      category_id: item.post?.category_id,
      created_at: item.post.created_at,
      updated_at: item.post?.updated_at,
      account_id: item.post?.account_id,
      isVisible: !this.state.isVisible,
    });
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOrder = (order_id: S["order_id"]) => {
    this.setState({ isVisible: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint + "/" + `${order_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = (token: string) => {
    if (
      this.isNumberNull(this.state.catalogue_id) ||
      this.isNumberNull(this.state.quantity) ||
      this.isNumberNull(this.state.taxable_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
      taxable: this.state.taxable,
      taxable_value: this.state.taxable_value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getCartOrders = () =>{
    let token = localStorage.getItem('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartOrdersCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_shopping_cart/order_items'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteOrderItem = async (
    order_id: string | number,
    order_item_id?: string | number
  ) => {
    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteOrderItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_shopping_cart/order_items/'+order_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setCatalogueId = (catalogue_id?: string) => {
    this.setState({ catalogue_id: Number(catalogue_id) || 0 });
  };

  setQuantity = (quantity?: string) => {
    this.setState({ quantity: Number(quantity) || 0 });
  };

  setTaxable = (taxable?: boolean) => {
    this.setState({ taxable: taxable || false });
  };

  setTaxableValue = (taxable_value?: string) => {
    this.setState({ taxable_value: Number(taxable_value) || 0 });
  };

  setVisible = (isVisible: boolean) => {
    this.setState({ isVisible: isVisible });
  };

  addOrderItem = () => {
    this.setState({ isVisible: false });
    this.navigateToAddShoppingCartOrderItem();
  };

  showOrderItems = (order_id: string | number) => {
    const order_id_num = Number(order_id) || 0;
    this.setState({ order_id: order_id_num });
    this.showOrder(order_id_num);
  };

  handleClickProccedToBuyItems = () => {
    this.setState({ isProccedToBuyButtonClicked: true });
  }

  handleCloseModal = () => {
    this.setState({ isProccedToBuyButtonClicked: false });
  }

  handleCloseConfirmOrderModal = () => {
    this.setState({ isConfirmOrderClicked: false });
  }

  handleClickConfirmOrders = async () => {
    const { cartItemList } = this.state;
    const order_items = cartItemList.map(item => ({ id: item.id }));

    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const body = {
      order_items,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.buyOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.proceedToBuyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleQuantityChange = async (orderId:number, productId: number, newQuantity: number) => {
    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const order_items = {
      quantity: newQuantity,
      product_id: productId
    }

    const body = {
      order_items,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateOrderQuantityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateOrderQuantity}/${orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResponseOrderItems = (responseJson: ShoppingCart) => {
    if(responseJson.cart_items){
      const filteredCartItems = responseJson.cart_items.filter(
        (item: CartItem) => item.order_management_order_id === null
      );
      this.setState({ cartItemList: filteredCartItems, cartDetails: responseJson.cart, loading: false });
    }
    else{
      this.setState({ cartItemList: [], loading: false });
    }
  }
  // Customizable Area End
}
