import React from "react";

// Customizable Area Start
// Customizable Area End

import FilterItemsBlockWebController, { Props } from "./FilterItemsBlockController.web";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  IconButton, 
  Select, 
  MenuItem, 
  FormControl,
  Divider,
  Button,
  Grid,
  Card,
  Checkbox, 
  ListItemText,
  SelectChangeEvent,
  InputAdornment,
  Slider,
  Chip,
  TextField,
  Pagination
} from "@mui/material";
import {
  Star as StarIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ViewList as ViewListIcon,
  GridView as GridViewIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  Sort as SortIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { AppHeader } from "../../../components/src/AppHeader.web";
import { imgDefaultProduct, imgNoProduct } from "./assets";
import Loader from "../../../components/src/Loader.web";

interface FilterState {
  type: string[];
  breeder: string[];
  strains: string[];
  county: string[];
  priceRange: number[];
}
// Customizable Area End

export default class FilterItemsBlockWeb extends FilterItemsBlockWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderConditionalComponent = (condition: boolean, ComponentIfTrue: JSX.Element, ComponentIfFalse: JSX.Element): JSX.Element => {
    return condition ? ComponentIfTrue : ComponentIfFalse;
  }

  isAnyFilterApplied = () => {
    const{ filter } = this.state;
    const isPriceRangeModified = filter.priceRange && (filter.priceRange[0] !== 0 || filter.priceRange[1] !== 1000);
    return (
      filter.type.length > 0 ||
      filter.breeder.length > 0 ||
      filter.strains.length > 0 ||
      filter.county.length > 0 || 
      filter.sort_by.length > 0 || 
      isPriceRangeModified
    );
  };

  renderValueLabel = (value: number) => {
    return `${value}$`
  };

  handleIncreaseQuantity = (index: number) => {
    const { quantities, products } = this.state;

    if (quantities[index] < products[index].attributes.available_quantity) {
      const newQuantities = [...quantities];
      newQuantities[index] += 1;
      this.setState({ quantities: newQuantities });
    }
  };

  handleDecreaseQuantity = (index: number) => {
    const { quantities, products } = this.state;

    if (quantities[index] > products[index].attributes.min_quantity) {
      const newQuantities = [...quantities];
      newQuantities[index] -= 1;
      this.setState({ quantities: newQuantities });
    }
  };

  removeConflictingOption = (
    options: string[],
    newOption: string,
    conflictingOptions: string[]
  ): string[] => {
    const updatedOptions = [...options];
    conflictingOptions.forEach((conflict) => {
      if (updatedOptions.includes(conflict) && conflict !== newOption) {
        updatedOptions.splice(updatedOptions.indexOf(conflict), 1);
      }
    });
    return updatedOptions;
  }

  handleChange = (event: SelectChangeEvent<unknown>) => {
    const { sort_by } = this.state.filter;
    const value = event.target.value;
    const selectedOptions = Array.isArray(value) ? value : [value as string];

    let updatedOptions = [...sort_by];
    
    selectedOptions.forEach((option) => {
      if (["high_to_low", "low_to_high"].includes(option)) {
        updatedOptions = this.removeConflictingOption(updatedOptions, option, ["high_to_low", "low_to_high"]);
      } else if (["a_to_z", "z_to_a"].includes(option)) {
        updatedOptions = this.removeConflictingOption(updatedOptions, option, ["a_to_z", "z_to_a"]);
      }
  
      if (!updatedOptions.includes(option) && option !== "Sort by") {
        updatedOptions.push(option);
      }
    });
    
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        sort_by: updatedOptions,
      },
    }));
  };
  
  getColumnSize = (isBuyerAccount: boolean, N1: number, N2: number): number => {
    return isBuyerAccount ? N1 : N2;
  }

  getColorProperty = (isBuyerAccount: boolean, S1: string, S2: string): string => {
    return isBuyerAccount? S1 : S2;
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      isBuyerAccount,
      viewMode,
      products,
      filter,
      quantities,
      page,
      per_page,
      pageForAdvancedSearch,
      perPageForAdvancedSearch,
      totalProducts,
      totalProductsAfterFilter,
      isFilterSuccessfullyApplied,
      filterOptions
    } = this.state;
    const options = [
      { label: "Price high to low", value: "high_to_low" },
      { label: "Price low to high", value: "low_to_high" },
      { label: "High customer rating", value: "high_customer_rating" },
      { label: "A - Z naming", value: "a_to_z" },
      { label: "Z - A naming", value: "z_to_a" },
      { label: "Only in stock", value: "only_in_stock" },
    ];    
    const totalPages = Math.ceil(totalProducts / per_page);
    const totalPagesAfterFilter = Math.ceil(totalProductsAfterFilter / perPageForAdvancedSearch);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <AppHeader navigtion={this.props.navigation} />
        <Container maxWidth={"xl"}>
          <Box sx={styles.containerBox}>
            {this.renderConditionalComponent(
              isBuyerAccount,
              <React.Fragment>
                <Grid container sx={styles.filterPart} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    {["Type", "Breeder", "Strains", "County"].map((label) => (
                      <Grid item xs={12} sm={6} md={3} key={label}>
                        <FormControl fullWidth variant="outlined">
                          <Select
                            name={label}
                            displayEmpty
                            value={
                              filter[label.toLowerCase() as keyof FilterState]
                            }
                            sx={{
                              borderRadius: "30px",
                              padding: "0px 20px",
                              minWidth: "120px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#cbd5e1",
                              },
                            }}
                            data-test-id={`filter-select-${label.toLowerCase()}`}
                            onChange={(event) =>
                              this.handleSelectChange(event, label)
                            }
                            renderValue={() => (
                              <span style={{ color: "#6b7280" }}>
                                {label.charAt(0).toUpperCase() + label.slice(1)}
                              </span>
                            )}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  marginTop: "10px",
                                },
                                style: {
                                  maxHeight: 185,
                                  overflow: "auto",
                                },
                              },
                            }}
                          >
                            {filterOptions[
                              label.toLowerCase() as keyof typeof filterOptions
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8} lg={8} container alignItems="end">
                      <Grid item marginTop={"22px"}>
                        <Typography
                          sx={{ whiteSpace: "nowrap", marginRight: "30px" }}
                        >
                          Price range
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={filter.priceRange}
                          data-test-id="price-range-slider"
                          valueLabelFormat={this.renderValueLabel}
                          onChange={(event, newValue) =>
                            this.handlePriceRangeChange(event, newValue)
                          }
                          valueLabelDisplay="on"
                          min={0}
                          max={1000}
                          sx={styles.sliderStyles}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                      <Button
                        variant="contained"
                        data-test-id="apply-filter-button"
                        fullWidth
                        sx={styles.applyFilterButton}
                        onClick={() => this.handleClickApplyFilterButton()}
                      >
                        Apply Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap="wrap"
                  marginBottom={this.isAnyFilterApplied() ? "30px" : undefined}
                >
                  {["Type", "Breeder", "Strains", "County"].map((label) => (
                    <React.Fragment key={label}>
                      {filter[label.toLowerCase() as keyof FilterState].map(
                        (selectedValue) => (
                          <Chip
                            key={selectedValue}
                            data-test-id={`filter-chip-${label.toLowerCase()}-${selectedValue}`}
                            label={selectedValue}
                            onDelete={() =>
                              this.handleDeleteChip(label, selectedValue)
                            }
                            deleteIcon={
                              <CloseIcon sx={{ fontSize: "small" }} />
                            }
                            sx={{ margin: "5px" }}
                          />
                        )
                      )}
                    </React.Fragment>
                  ))}
                  {filter.priceRange &&
                  (filter.priceRange[0] !== 0 ||
                    filter.priceRange[1] !== 1000) ? (
                    <Chip
                      data-test-id="filter-chip-price-range"
                      label={`Price ${filter.priceRange[0]}$ - ${filter.priceRange[1]}$`}
                      onDelete={() => this.handleDeletePriceChip()}
                      deleteIcon={<CloseIcon sx={{ fontSize: "small" }} />}
                      sx={{ margin: "5px" }}
                    />
                  ) : null}
                  {this.renderConditionalComponent(
                    this.isAnyFilterApplied(),
                    <Typography
                      sx={styles.clearAllFilterText}
                      data-test-id="clear-all-filters-text"
                      onClick={() => this.clearAllFilters()}
                    >
                      Clear all filters
                    </Typography>,
                    <React.Fragment></React.Fragment>
                  )}
                </Box>
                <Divider />
              </React.Fragment>,
              <React.Fragment></React.Fragment>
            )}
            <Box sx={styles.innerHeader}>
              {this.renderConditionalComponent(
                isBuyerAccount,
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    Products ({products.length})
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Based on filter or search result
                  </Typography>
                </Box>,
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    Your listings
                  </Typography>
                </Box>
              )}
              <Box
                display="flex"
                alignItems="center"
                marginLeft={"auto"}
                width={isBuyerAccount ? undefined : "65%"}
              >
                {this.renderConditionalComponent(
                  isBuyerAccount,
                  <React.Fragment></React.Fragment>,
                  <React.Fragment>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      sx={{ padding: "10px 16px", maxWidth: "100%" }}
                    >
                      <Grid item xs={12} sm={8} md={9}>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          variant="outlined"
                          sx={{
                            borderRadius: "50px",
                            backgroundColor: "#F1F5F9",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon sx={{ color: "#94A3B8" }} />
                              </InputAdornment>
                            ),
                            sx: {
                              borderRadius: "50px",
                              paddingLeft: "10px",
                            },
                          }}
                          inputProps={{
                            sx: {
                              padding: "11px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={styles.addProductButton}
                          data-test-id="add-product-button"
                          onClick={() => this.handleAddProduct()}
                        >
                          Add Product
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <FormControl
                  variant="standard"
                  size="small"
                  sx={{ minWidth: 180, marginRight: 2 }}
                >
                  <Select
                    multiple
                    value={["Sort by"]}
                    onChange={this.handleChange}
                    data-test-id="sort-select"
                    startAdornment={
                      <InputAdornment position="start">
                        <SortIcon />
                      </InputAdornment>
                    }
                    variant="standard"
                    sx={{
                      ".MuiSelect-select": {
                        padding: 0,
                        textAlign: "center",
                      },
                    }}
                    renderValue={(selected) => {
                      return selected.map((opt) =>
                        opt === "Sort by" ? "Sort by" : null
                      );
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          marginTop: "20px",
                          padding: "12px 20px 2px",
                        },
                      },
                    }}
                  >
                    <MenuItem sx={styles.menuItem} disabled>
                      <Box sx={styles.sortByText}>Sort by</Box>
                    </MenuItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={styles.menuItem}
                      >
                        <Checkbox
                          checked={filter.sort_by.includes(option.value)}
                          sx={{
                            padding: "0px 5px 0px 0px",
                            "&.Mui-checked": {
                              color: "#057C3F",
                            },
                            "& .MuiSvgIcon-root": {
                              borderRadius: "6px",
                            },
                          }}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  data-test-id="list-button"
                  onClick={() => this.handleViewChange("list")}
                  sx={{
                    color: this.getColorProperty(
                      viewMode === "list",
                      "#000000",
                      "default"
                    ),
                  }}
                >
                  <ViewListIcon />
                </IconButton>
                <IconButton
                  data-test-id="grid-button"
                  onClick={() => this.handleViewChange("grid")}
                  sx={{
                    color: this.getColorProperty(
                      viewMode === "grid",
                      "#000000",
                      "default"
                    ),
                  }}
                >
                  <GridViewIcon />
                </IconButton>
              </Box>
            </Box>
            {this.renderConditionalComponent(
              viewMode === "list",
              <React.Fragment>
                {this.renderConditionalComponent(
                  !this.state.loading && products.length === 0,
                  <React.Fragment>
                    <Box
                      display={"flex"}
                      width={"100%"}
                      height={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                    >
                      <img src={imgNoProduct} />
                      {this.renderConditionalComponent(
                        isBuyerAccount,
                        <Typography sx={styles.noProductBuyer}>
                          No items were found that match your search and filter
                          criteria.Change your search and filter criteria to see
                          products.
                        </Typography>,
                        <Typography sx={styles.noProduct}>
                          Click add product to add your first product
                        </Typography>
                      )}
                    </Box>
                  </React.Fragment>,
                  <React.Fragment>
                    {products.map((product, index) => {
                      const {
                        name,
                        price,
                        classification,
                        min_quantity,
                        available_quantity,
                        images,
                        type,
                      } = product.attributes;
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{
                              padding: "20px",
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Box
                                  component="img"
                                  src={images?.[0]?.url ?? imgDefaultProduct}
                                  alt={name}
                                  sx={{
                                    width: 108,
                                    height: 86,
                                    borderRadius: 2,
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                  data-test-id="view-button-list"
                                  onClick={() => this.handleNavigation(`${product.id}`)}
                                />
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  marginLeft={3}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={styles.headerText}
                                  >
                                    {name}{" "}
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      color="green"
                                    >
                                      ({type} stock)
                                    </Typography>
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={styles.priceColor}
                                  >
                                    ${price} USD
                                  </Typography>
                                  {this.renderConditionalComponent(
                                    isBuyerAccount,
                                    <Box display={"flex"} alignItems={"center"}>
                                      <Box sx={styles.chipStyle}>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          fontSize={"12px"}
                                        >
                                          <b color="black">Breeder:</b> Auto
                                          Seeds
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        marginLeft={1}
                                      >
                                        <StarIcon
                                          sx={{
                                            color: "#FBBF24",
                                            marginRight: 1,
                                          }}
                                        />
                                        <Typography variant="body2">
                                          4.5
                                        </Typography>
                                      </Box>
                                    </Box>,
                                    <>
                                      <Typography
                                        variant="h6"
                                        sx={styles.headerText}
                                      >
                                        Classification :{" "}
                                        <Typography
                                          component="span"
                                          color={"#4F46E5"}
                                        >
                                          {classification}
                                        </Typography>
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                              </Box>
                              {this.renderConditionalComponent(
                                isBuyerAccount,
                                <Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        this.handleDecreaseQuantity(index)
                                      }
                                      disabled={
                                        quantities[index] <= min_quantity
                                      }
                                      data-test-id="decrease-button-list"
                                    >
                                      <ArrowBackIosIcon fontSize="small" />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={styles.quantityColor}
                                    >
                                      {quantities[index]}
                                    </Typography>
                                    <IconButton
                                      onClick={() =>
                                        this.handleIncreaseQuantity(index)
                                      }
                                      disabled={
                                        quantities[index] >= available_quantity
                                      }
                                      data-test-id="increase-button-list"
                                    >
                                      <ArrowForwardIosIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                  <Box mt={1}>
                                    <Button
                                      sx={styles.addToCardButton}
                                      fullWidth
                                      onClick={() =>
                                        this.handleAddToCartButton(
                                          +product.id,
                                          quantities[index]
                                        )
                                      }
                                      data-test-id="add-to-cart-button-list"
                                    >
                                      <b>Add to cart</b>
                                    </Button>
                                  </Box>
                                </Box>,
                                <>
                                  <Box sx={styles.middleQuantityButton}>
                                    <Typography sx={styles.quantityCountText}>
                                      {available_quantity}
                                    </Typography>
                                  </Box>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box
                                      sx={styles.listDeleteText}
                                      data-test-id="delete-button-list"
                                      onClick={() =>
                                        this.handleDeleteProduct(+product.id)
                                      }
                                    >
                                      <IconButton>
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                      Delete
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box
                                      sx={styles.listEditText}
                                      data-test-id="edit-button-list"
                                      onClick={()=> this.handleEditProduct(product.id)}
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                      Edit
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Divider sx={{ backgroundColor: "#E7E5E4" }} />
                        </>
                      );
                    })}
                  </React.Fragment>
                )}
              </React.Fragment>,
              <Box marginTop={"40px"}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={this.getColumnSize(isBuyerAccount, 11, 12)}
                  >
                    <Grid container spacing={4}>
                      {this.renderConditionalComponent(
                        !this.state.loading && products.length === 0,
                        <React.Fragment>
                          <Box
                            display={"flex"}
                            width={"100%"}
                            marginTop={"50px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                          >
                            <img src={imgNoProduct} />
                            {this.renderConditionalComponent(
                              isBuyerAccount,
                              <Typography sx={styles.noProductBuyer}>
                                No items were found that match your search and
                                filter criteria.Change your search and filter
                                criteria to see products.
                              </Typography>,
                              <Typography sx={styles.noProduct}>
                                Click add product to add your first product
                              </Typography>
                            )}
                          </Box>
                        </React.Fragment>,
                        <React.Fragment>
                          {products.map((product, index) => {
                            const {
                              name,
                              price,
                              images,
                              available_quantity,
                              min_quantity,
                              classification,
                              type,
                            } = product.attributes;
                            return (
                              <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                              >
                                <Card sx={styles.cardMain}>
                                  <Box
                                    component="img"
                                    src={images?.[0]?.url ?? imgDefaultProduct}
                                    alt={name}
                                    sx={{
                                      width: "100%",
                                      height: "135px",
                                      borderRadius: 2,
                                      objectFit: "cover",
                                      cursor: "pointer",
                                    }}
                                    data-test-id="view-button-grid"
                                    onClick={() => this.handleNavigation(`${product.id}`)}
                                  />
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    marginTop={"6px"}
                                  >
                                    {this.renderConditionalComponent(
                                      isBuyerAccount,
                                      <React.Fragment>
                                        <Typography
                                          variant="body2"
                                          color="#475569"
                                          fontSize={"12px"}
                                        >
                                          <b color="black">Breeder:</b> Auto
                                          Seeds
                                        </Typography>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          marginLeft={1}
                                        >
                                          <StarIcon
                                            sx={{
                                              color: "#FBBF24",
                                              marginRight: 1,
                                            }}
                                          />
                                          <Typography variant="body2">
                                            4.5
                                          </Typography>
                                        </Box>
                                      </React.Fragment>,
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={styles.sellerHeaderText}
                                        >
                                          {name}{" "}
                                          <Typography
                                            variant="body2"
                                            component="span"
                                            color="green"
                                            fontFamily={"Inter"}
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                          >
                                            ({type} stock)
                                          </Typography>
                                        </Typography>
                                      </React.Fragment>
                                    )}
                                  </Box>
                                  {this.renderConditionalComponent(
                                    isBuyerAccount,
                                    <React.Fragment>
                                      <Box marginTop={"10px"}>
                                        <Typography
                                          variant="h6"
                                          sx={styles.headerText}
                                        >
                                          {name}{" "}
                                          <Typography
                                            variant="body2"
                                            component="span"
                                            color="green"
                                          >
                                            ({type} stock)
                                          </Typography>
                                        </Typography>
                                      </Box>
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        marginTop={"10px"}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            this.handleDecreaseQuantity(index)
                                          }
                                          disabled={
                                            quantities[index] <= min_quantity
                                          }
                                          data-test-id="decrease-button-grid"
                                        >
                                          <RemoveIcon
                                            fontSize="small"
                                            sx={styles.iconColor}
                                          />
                                        </IconButton>
                                        <Box sx={styles.quantityChip}>
                                          {quantities[index]}
                                        </Box>
                                        <IconButton
                                          onClick={() =>
                                            this.handleIncreaseQuantity(index)
                                          }
                                          disabled={
                                            quantities[index] >=
                                            available_quantity
                                          }
                                          data-test-id="increase-button-grid"
                                        >
                                          <AddIcon
                                            fontSize="small"
                                            sx={styles.iconColor}
                                          />
                                        </IconButton>
                                      </Box>
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        marginTop={"10px"}
                                      >
                                        <Typography
                                          variant="h6"
                                          sx={styles.priceColor}
                                        >
                                          ${price} USD
                                        </Typography>
                                        <Button
                                          sx={styles.addToCardButton}
                                          onClick={() =>
                                            this.handleAddToCartButton(
                                              +product.id,
                                              quantities[index]
                                            )
                                          }
                                          data-test-id="add-to-cart-button-grid"
                                        >
                                          <b>Add to cart</b>
                                        </Button>
                                      </Box>
                                    </React.Fragment>,
                                    <React.Fragment>
                                      <Box marginTop={"10px"}>
                                        <Typography
                                          variant="h6"
                                          sx={styles.priceColor}
                                        >
                                          ${price} USD
                                        </Typography>
                                      </Box>
                                      <Box marginTop={"10px"}>
                                        <Typography
                                          sx={styles.sellerAvailableQuantity}
                                        >
                                          {available_quantity}
                                        </Typography>
                                      </Box>
                                      <Box marginTop={"10px"}>
                                        <Typography
                                          variant="h6"
                                          sx={styles.headerText}
                                        >
                                          Classification :{" "}
                                          <Typography
                                            component="span"
                                            color={"#4F46E5"}
                                          >
                                            {classification}
                                          </Typography>
                                        </Typography>
                                      </Box>
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        marginTop={"5px"}
                                      >
                                        <Box
                                          sx={styles.listSellerDeleteText}
                                          data-test-id="delete-button-grid"
                                          onClick={() =>
                                            this.handleDeleteProduct(
                                              +product.id
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <DeleteOutlineIcon />
                                          </IconButton>
                                          Delete
                                        </Box>
                                        <Divider
                                          orientation="vertical"
                                          flexItem
                                        />
                                        <Box
                                          sx={styles.listSellerEditText}
                                          data-test-id="edit-button"
                                          onClick={()=> this.handleEditProduct(product.id)}
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                          Edit
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                                </Card>
                              </Grid>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                  {this.renderConditionalComponent(
                    isBuyerAccount,
                    <Grid item xs={false} sm={false} md={false} lg={1}></Grid>,
                    <Grid
                      item
                      xs={false}
                      sm={false}
                      md={false}
                      lg={false}
                    ></Grid>
                  )}
                </Grid>
              </Box>
            )}
            {this.renderConditionalComponent(
              products.length > 0,
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                margin={"30px 0px"}
              >
                {this.renderConditionalComponent(
                  isFilterSuccessfullyApplied,
                  <Pagination
                    count={totalPagesAfterFilter}
                    page={pageForAdvancedSearch}
                    data-test-id="pagination-after-filter"
                    onChange={this.handlePageChangeAfterFilter}
                    siblingCount={1}
                    boundaryCount={1}
                    sx={styles.paginationColor}
                  />,
                  <Pagination
                    count={totalPages}
                    page={page}
                    data-test-id="pagination"
                    onChange={this.handlePageChange}
                    siblingCount={1}
                    boundaryCount={1}
                    sx={styles.paginationColor}
                  />
                )}
              </Box>,
              <React.Fragment></React.Fragment>
            )}
          </Box>
        </Container>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles = {
  sortByText: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "14px",
  },
  paginationColor: {
    "& .MuiPaginationItem-root": {
      color: "#000",
      textDecoration: "underline",
    },
    "& .Mui-selected": {
      color: "#6200EA",
      backgroundColor: "#fff !important",
      textDecoration: "none",
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#000",
    },
  },
  addProductButton: {
    backgroundColor: "#067B3E",
    borderRadius: "50px",
    textTransform: "none",
    padding: "10px",
    fontWeight: "700",
    fontsize: "16px",
    "&:hover": {
      backgroundColor: "#067B3E",
    },
  },
  applyFilterButton: {
    backgroundColor: "#067B3E",
    borderRadius: "50px",
    textTransform: "none",
    padding: "16px",
    fontWeight: "700",
    fontsize: "16px",
    "&:hover": {
      backgroundColor: "#067B3E",
    },
  },
  sellerAvailableQuantity: {
    display: "inline-block",
    borderRadius: "8px",
    padding: "6px 6px",
    color: "#334155",
    fontSize: "14px",
    backgroundColor: "#F5F5F4",
    fontWeight: "400",
  },
  noProductBuyer:{
    textAlign: "center",
    width:"60%",
    marginTop: "20px",
    color: "black",
    fontSize: "25px",
    fontWeight: "500",
  },
  noProduct: {
    marginTop: "20px",
    color: "black",
    fontSize: "50px",
    fontWeight: "500",
  },
  clearAllFilterText: {
    cursor: "pointer",
    color: "#4F46E5",
    fontWeight: "400",
    fontSize: "14px",
    marginLeft: "10px",
  },
  sliderStyles: {
    "& .MuiSlider-thumb": {
      color: "#2E7D32",
    },
    "& .MuiSlider-track": {
      backgroundColor: "#2E7D32",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#d0d0d0",
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#ECFDF5",
      color: "black",
      padding: "5px 10px",
      borderRadius: "4px",
      "&::before": {
        display: "none",
      },
    },
  },
  filterPart: {
    display: "flex",
    alignItems: "center",
    marginTop: "26px",
    marginBottom: "30px",
  },
  listItem: {
    color: "#334155 !important",
    fontWeight: "700 !important",
    fontSize: "14px !important",
  },
  menuItem: {
    color: "black",
    display: "flex",
    alignItems: "center",
    padding: 0,
    marginBottom: "10px",
    "&.Mui-disabled": {
      color: "black !important",
      opacity: "1 !important",
    },
  },
  iconColor: {
    color: "black",
  },
  quantityChip: {
    backgroundColor: "#F5F5F4",
    borderRadius: "8px",
    padding: "6px 4px",
    fontFamily: "Inter, sans-serif",
    color: "#334155",
    fontSize: "14px",
    fontWeight: "400",
  },
  cardMain: {
    backgroundColor: "#FFF",
    borderRadius: "17px",
    padding: "10px 10px 18px",
  },
  listDeleteText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "500",
    color: "#334155",
    padding: "5px 28px",
    fontFamily: "Inter, sans-serif",
  },
  listSellerDeleteText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "500",
    color: "#334155",
    paddingLeft: "5px",
    paddingRight: "28px",
    fontFamily: "Inter, sans-serif",
  },
  listEditText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "500",
    color: "#334155",
    padding: "5px 28px",
    fontFamily: "Inter, sans-serif",
  },
  listSellerEditText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "500",
    color: "#334155",
    paddingLeft: "20px",
    paddingRight: "28px",
    fontFamily: "Inter, sans-serif",
  },
  quantityCountText: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#44403C",
    fontFamily: "Inter, sans-serif",
  },
  middleQuantityButton: {
    backgroundColor: "#F5F5F4",
    borderRadius: "8px",
    padding: "14px 53px",
    fontFamily: "Inter, sans-serif",
  },
  chipStyle: {
    backgroundColor: "#F3F3F3",
    borderRadius: "40px",
    padding: "2px 8px",
    fontFamily: "Inter, sans-serif",
  },
  sellerHeaderText: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#334155",
    fontFamily: "Inter",
    marginTop: "6px",
  },
  headerText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#334155",
    fontFamily: "Inter, sans-serif",
    marginBottom: "4px",
  },
  priceColor: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#334155",
    fontFamily: "Inter, sans-serif",
    marginBottom: "4px",
  },
  containerBox: {
    backgroundColor: "#fff",
  },
  innerHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px 0px",
    fontFamily: "Inter, sans-serif",
  },
  addToCardButton: {
    cursor: "pointer",
    textTransform: "none",
    padding: "6px 18px",
    backgroundColor: "#007A2F33",
    color: "#007A2F",
    borderRadius: "8px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      color: "#007A2F",
      backgroundColor: "#007A2F33",
    },
  },
  quantityColor: {
    fontWeight: "700",
    fontSize: "14px",
  },
};
// Customizable Area End