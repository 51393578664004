import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import { AppHeader } from "../../../components/src/AppHeader.web";
import {
  Card,
  Box,
 
  Divider,
  Grid,
  CardContent,
  Avatar,
  Typography as TypographyMui,
  Button as ButtonMui,
} from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EditProfileDrawer from './components/EditProfileDrawer'
import SubscriptionCancelModal from "./components/SubscriptionCancelModal";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  pageSpesificStyle = {
  tileText :{
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    textAlign: 'left',
  },
  subText :{
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'left',
    color : '#64748B'
  }

  }


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width:'100vw' }}>
        <EditProfileDrawer open={this.state.isEditOpen} onClose={this.chageIsEditOpen}  user={this.state.user}      
          submitFn={(e)=>{this.handelSubmigForm(e)}}
        /> 
        
        <SubscriptionCancelModal 
          open={this.state.isSubscriptionCancelModalOpen}
          onClose={this.closeSubscriptionCancelModal}
          onConfirm={this.confirmCancelSubscription}
        />

      <AppHeader navigtion={this.props.navigation}/>  
      <Grid container spacing={2} style={{justifyContent:'center', alignItems:'center'}}>
      <Grid item>
        <Card sx={{ maxWidth:'700px',minWidth:'600px', mt: 4 , border:'none',boxShadow:'none'}}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{display:'flex', flexDirection:'column'}}>
                <Avatar
                src= {this.state.user.attributes?.photo_url??""}
                sx={{ width: '132px', height: '132px', mr: '86px' , border:'4px solid #F0E5FF' }}
              >{}</Avatar>
              <Box>
                <ButtonMui
                      variant="contained"
                      size="small"
                      onClick={this.chageIsEditOpen}
                      sx={{
                        marginTop: '20px',
                        marginLeft:'12px',
                        width: '116px',
                        height: '32px',
                        padding: '10px 21px',
                        gap: '8px',
                        borderRadius: '18px',
                        border: '1px solid transparent',
                        borderTopWidth: '1px',
                        textTransform:'none',
                        fontSize:'12px',
                        opacity: 1, // Opacity 0 would make it invisible, set to 1 for visible
                        backgroundColor: '#067B3E',
                        '&:hover': {
                          backgroundColor: '#056635', // optional, adjust hover effect
                        }
                      }}
                      
                    >
                      Edit profile
                    </ButtonMui>
              </Box>
              </Box>
              
              <Box>
              <TypographyMui
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '26px',
                  textAlign: 'left',
                }}
              >{this.state.user.attributes?.contact_name}
               </TypographyMui>
               <div style={{display:"flex", alignItems:'center' , gap:'5px'}}>
               <FmdGoodIcon  sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '16px',
                  fontWeight: 400,
                  textAlign: 'center',
                  color : '#64748B'
                }}/>
               <TypographyMui color="text.secondary"
                 sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color : '#64748B'
                
                }}
                >
                  {this.state.user.attributes?.state}
                </TypographyMui>
               </div>
                
                <TypographyMui variant="body2" color="text.secondary" 
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color : '#64748B'
                }}
                >
                  License Number:  #{this.state.user.attributes?.license_number}
                </TypographyMui>
                <Box sx={{ display: 'flex',justifyContent: 'space-between',alignItems:'center',flexDirection:'row', gap:'20px' , marginTop:'20px'}}>
                  
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <PhoneEnabledIcon fontSize="small" sx={{ ...this.pageSpesificStyle.subText , mr:1 }} />
                      <TypographyMui variant="body2" sx={{ ...this.pageSpesificStyle.subText , mr:1 }}>{this.state.user.attributes?.phone_number}</TypographyMui>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <EmailRoundedIcon fontSize="small" sx={{ ...this.pageSpesificStyle.subText , mr:1 }} />
                      <TypographyMui variant="body2" sx={{ ...this.pageSpesificStyle.subText , mr:1 }}>{this.state.user.attributes?.email}</TypographyMui>
                    </Box>
                  
                  
            </Box>
              </Box>
            </Box>

            

            <Divider sx={{ my: 2 }} />

            <TypographyMui variant="h6" gutterBottom sx={this.pageSpesificStyle.tileText}>
              Business Information
            </TypographyMui>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  Business Name
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  Address
                </TypographyMui>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TypographyMui variant="subtitle2" sx={this.pageSpesificStyle.subText}> {this.state.user.attributes?.name_of_business}</TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                  {this.state.user.attributes?.address}
                </TypographyMui>
              </Grid>
           
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  {this.state.user.attributes?.type === "BuyerAccount"?"Buyer": "Seller"} License
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
               
                </TypographyMui>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TypographyMui variant="subtitle2" sx={this.pageSpesificStyle.subText}>  #{this.state.user.attributes?.license_number}</TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                </TypographyMui>
              </Grid>
              </Grid>
            
            <Divider sx={{ my: 2 }} />

            {this.state.user.attributes?.subscribed &&
            <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyMui variant="h6" gutterBottom sx={this.pageSpesificStyle.tileText}>
                  Subscription Details
                </TypographyMui>
                <ButtonMui onClick={this.openSubscriptionCancelModal} variant="text" style={{textTransform: "none"}}>
                  Cancel Subscription
                </ButtonMui>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Subscription status
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Subscribed on
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Renewal date
                </TypographyMui>
                
              </Grid>
              <Grid item sm={6}>
              <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                Active
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                {this.state.user.attributes?.start_date.split("T")[0]}
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                {this.state.user.attributes?.end_date.split("T")[0]}
                </TypographyMui>
              </Grid>
             
           
            </Grid>
            
            </>}
            
          </CardContent>
        </Card>
      </Grid>
      </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
