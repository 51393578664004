import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";


export const baseConfigJson  = require("../../../framework/src/config.js");
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}

export interface Specification {
  key: string;
  value: string;
}

export interface AddProductState {
  name: string;
  description: string;
  specifications: Specification[];
  availableQty: string;
  price: string,
  classification: string;
  type: string;
  images: File[];
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  addProductState: AddProductState;
  // productImages: File[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  addProductApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      addProductState: {
        name: '',
        description: '',
        specifications: [{ key: '', value: '' }],
        availableQty: '',
        price: '',
        classification: '',
        type: '',
        images: [],
      },
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.addProductApiCallId) {
        this.handleAddProductResponse(responseJson);
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const user = JSON.parse(await getStorageData("user"));
    this.setState({userType: user.attributes.type});
}
  handleAddProductResponse = (responseJson: any) => {
    if (responseJson.errors) {
      toast.error(responseJson.errors);
    } else {
      toast.success("Product added successfully!");
      this.resetForm();
    }
    this.setState({ loading: false });
  };

  resetForm = () => {
    this.setState({
      addProductState: {
        name: '',
        description: '',
        specifications: [{ key: '', value: '' }],
        availableQty: '',
        price: '',
        classification: '',
        type: '',
        images: [],
      }
    });
  };
;



  handleImageUpload = (files: File[]) => {
    this.setState(prevState => ({
      addProductState: {
        ...prevState.addProductState,
        images: [...prevState.addProductState.images, ...files]
      }
    }));
  };

  removeImage = (index: number) => {
    this.setState(prevState => ({
      addProductState: {
        ...prevState.addProductState,
        images: prevState.addProductState.images.filter((_, i) => i !== index)
      }
    }));
  };

  handleFormSubmit = async (values: AddProductState) => {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("products[name]", values.name);
    formData.append("products[product_feature]", values.description);
    formData.append("products[price]", values.price);
    formData.append("products[type]", values.type);
    formData.append("products[classification]", values.classification);
    formData.append("products[min_quantity]", "1");
    formData.append("products[available_quantity]", values.availableQty);
  
    values.specifications.forEach((spec, index) => {
      formData.append(`products[specifications_attributes][${index}][key]`, spec.key);
      formData.append(`products[specifications_attributes][${index}][value]`, spec.value);
    });
  
    this.state.addProductState.images.forEach((image, index) => {
      formData.append(`products[images][]`, image as Blob);
    });
  
  
    const token = await getStorageData("authToken");
  
    try {
      const response = await fetch(`${baseConfigJson.baseURL}${configJSON.addProductApiEndPoint}`, {
        method: configJSON.addProductApiMethod,
        headers: {
          token: token
        },
        body: formData,
      });
       await response.json();
  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  // Customizable Area End
}
