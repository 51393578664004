import React from 'react';
// Customizable Area Start
import { Grid, Paper, Box } from '@mui/material';
import { View, StyleSheet } from 'react-native';

interface FormWrapperProps {
    children: React.ReactNode;
}

const FormWrapper: React.FC<FormWrapperProps> = ({ children }) => {
    return (
        <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
            <Box
                sx={{
                    my: 1,
                    mx: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'right',
                }}
            >
                <Box sx={{ mt: 1 }}>
                    <Grid container sx={{ px: '43px', display: 'flex', flexDirection: 'column' }}>
                        <View style={styles.formWrapper}>
                            {children}
                        </View>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
};

const styles = StyleSheet.create({
    formWrapper: {
        display: "flex",
        width: "100%",
        paddingTop: "20px"
    }
});

export default FormWrapper;