import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
type NullableString = string | null | undefined;
type NullableNumber = number | null | undefined;
interface AccountAttributes {
  address: NullableString;
  business_description: NullableString;
  contact_name: NullableString;
  email: NullableString;
  phone_number: NullableNumber;
  type: NullableString;
  county: NullableString;
  state: NullableString;
  license_number: NullableNumber;
  name_of_business: NullableString;
  unique_auth_id: NullableString;
  created_at: NullableString;
  updated_at: NullableString;
  email_verified: boolean | null | undefined; // You can keep this as is or create another alias if needed
  photo_url: NullableString;
}

export interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}
// @ts-ignore
import {baseURL} from '../../../framework/src/config.js'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  user : Account|any;
  isEditOpen : boolean;
  signUpFormData:Account|any;
  isSubscriptionCancelModalOpen: boolean;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountApiCallId:string =""
  cancelSubscriptionApiCallId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    let parsedUser = null;
    const user = localStorage.getItem('user');
    if(user){
    parsedUser = JSON.parse(user).data || JSON.parse(user);}
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.PostEntityMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      user: parsedUser,
      isEditOpen:false,
      signUpFormData: null,
      isSubscriptionCancelModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.cancelSubscriptionApiCallId) {
        this.handleNavigation("EmailAccountLoginBlock");
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleNavigation = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    runEngine.sendMessage(message.id, message);
  }

  chageIsEditOpen=()=>{
    this.setState({isEditOpen:!this.state.isEditOpen})
  }
  handelSubmigForm(data:any){
    this.setState({signUpFormData:data})
    this.updateAccountApiCall()
   
  }
  updateAccountApiCall(): any {
    // Prepare the authorization headers
    const header = {
      "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
      "token": `${localStorage.getItem("authToken")}`,
    };
  
    const {
      contact_name,
      email,
      phone_number,
      business_description,
      photo
      // @ts-ignore
    } = this.state.signUpFormData;
  
    const formData = new FormData();
    formData.append("data[attributes][contact_name]", contact_name);
    formData.append("data[attributes][phone_number]", "+" + phone_number);
    formData.append("data[attributes][business_description]", business_description);
  
    if (photo && typeof photo !== 'string') {
      formData.append("data[attributes][photo]", photo);
    }
    if(this.state.user){

      this.setState(
        {user:{...this.state.user,contact_name:contact_name, photo,phone_number}})
    }
    fetch(`${baseURL}/${configJSON.userPatchApi}${this.state.user.id}`, {
      method: 'PATCH',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
        "token": `${localStorage.getItem("authToken")}`
        
      },
      body: formData 
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const currentUserData = JSON.parse(localStorage.getItem('user') || '{}');
      const updatedUserData = {
        ...currentUserData,
        ...data.data,
      };
      localStorage.setItem('user', JSON.stringify(updatedUserData));
      
      this.setState({ 
        user: updatedUserData,
        isEditOpen: false
      });
    })
    
    return true;}

    openSubscriptionCancelModal = () => {
      this.setState({ isSubscriptionCancelModalOpen: true });
    };
  
    closeSubscriptionCancelModal = () => {
      this.setState({ isSubscriptionCancelModalOpen: false });
    };
  
    confirmCancelSubscription = async () => {
      const accountId = this.state.user.id;
      const token = await getStorageData("authToken");
      const apiEndpoint = `bx_block_subscription_billing/recurring_subscriptions/${accountId}/cancel`;
      const header = {
        "Content-Type": "application/json",
        "token" : token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.cancelSubscriptionApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return this.cancelSubscriptionApiCallId
    };
  
  

  // Customizable Area End
}
