import React from "react";
// Customizable Area Start
import {
  StyleSheet,
 
  SafeAreaView,
 
} from "react-native";
import {Grid, Box,
  Button,
  Typography,
  Select,
  MenuItem,} from "@mui/material"
import { AppHeader } from "../../../components/src/AppHeader.web";
import {prdImg} from './assets'
import {Star} from '@mui/icons-material';
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";
import { bold } from "@uiw/react-md-editor";
import { isArray } from "lodash";

export default class ProductDiscription extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(){
    let id = new URLSearchParams(window.location.search).get('navigationBarTitleText');
    this.getProductDetails(+`${id}`)
      
  }

  isBuyerAccount= JSON.parse(localStorage.getItem('user') || '{}')?.attributes?.type === "BuyerAccount"
  // Customizable Area End

  render() {
    const images = this.state.selectedProduct?.attributes?.images || [];    
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
        {/* Customizable Area Start */}
        <AppHeader navigtion={this.props.navigation} />
        <Box sx={{ maxWidth: '75%', margin: '0 auto', padding: '20px' }}>
        {/* Back to Products Link */}
        <Typography
          data-test-id='backbtn'
          variant="body1"
          onClick={()=>this.handleBackProductsBtnClick()}
          sx={{ fontSize: '16px',color:'#9a9ea8', fontFamily:'inter',fontWeight:500, marginBottom: '20px', cursor: 'pointer' }}
        >
          <span style={{fontFamily:'inter', fontWeight:'bold'}}>❮</span> Back to products
        </Typography>
        <Box sx={{ display: 'flex', gap: '40px' , justifyContent:'space-between' , alignItems:'center' }}>
         
         
            <Box sx={{ width: '50%' }}>
              <div style={{ display: 'flex', gap: '16px', justifyContent: 'center', alignItems: 'center' }}>
                <span onClick={this.handlePrevious} data-test-id="span_btn_first" style={{ cursor: 'pointer', display: images.length > 1 ? "block" : "none"}}>❮</span>
                <img
                  src={images[this.state.selectedImageIndex2]?.url || prdImg.default}
                  alt="Product Main"
                  style={{
                    width: '100%',
                    minWidth: '292px',
                    objectFit: 'cover',
                    height: '382px',
                    maxWidth: '292px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                  }}
                />
                <span onClick={this.handleNext} data-test-id="span_btn_second" style={{ cursor: 'pointer', display: images.length > 1 ? "block" : "none"}}>❯</span>
              </div>

              <Grid container spacing={1} sx={{ px: '32px', pl: '0px', margin: '-4px' }}>
                {images.map((image, index) => (
                  <Grid item xs={4} sx={{ px: 0, m: 0 }} key={index}>
                    <img
                      src={image.url}
                      alt={`Thumbnail ${index + 1}`}
                      data-test-id="img_thumbnail"
                      onClick={() => this.handleThumbnailClick(index)}
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        border: index === this.state.selectedImageIndex2 ? '2px solid blue' : 'none',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          
          <Box sx={{display:'flex', alignItems:'flex-start', alignSelf:'start'}}>
          <Box sx={{   minWidth:'436px', alignItems:'start'}}>
            <Typography variant="h5" sx={{ fontWeight: '800', textTransform: "uppercase",fontFamily:'inter', fontSize: '18px', color: "rgb(16, 23, 42)" }}>
              {this.state.selectedProduct?.attributes.name}
            </Typography>
            
            <Typography variant="body2" sx={{ fontSize: '14px',color:'#0F172A', fontFamily:'inter', marginBottom: '16px', fontWeight: 500 }}>
              ({this.state.selectedProduct?.attributes.type})
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily:'inter',color: '#9399a2', marginBottom: '20px',fontWeight: "bold" }}>
            {this.state.selectedProduct?.attributes.product_feature}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '24px', fontFamily:'inter',color: '#334155', fontWeight: '700', marginBottom: '20px' }}>
              ${this.state.selectedProduct?.attributes.price} USD 
            </Typography>
            <Box sx={{ display:"flex" ,justifyContent:"space-between", borderTop:'1px solid #E2E8F0'}}>
            <Box sx={{ display: 'flex', paddingTop:'20px',flexDirection:'column', fontFamily:'inter',alignItems: 'flex-start', marginBottom: '20px' }}>
            
            {this.state.selectedProduct?.attributes.specifications_attributes?.map(item=>{
              return <Typography variant="body1" sx={{ fontSize: '16px' , fontFamily:'inter',color: '#44403C'}}>
                {item.key} : {item.value} 
                </Typography>
            })}
            
          
          </Box>
          <span style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px", color:'#64748B', fontWeight: 500, marginTop: "-18px",  marginRight :"30px" }}>
              <><Star style={{ fill: '#FBBF24' }} />0</>
            </span>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center',borderTop:'1px solid #E2E8F0', marginBottom: '20px' }}>
            </Box>
            <Box data-test-id="box1" sx={{ display: this.isBuyerAccount ? 'flex': "none",justifyContent:'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#9399a2', fontFamily:'inter', marginRight: '10px',fontWeight: "bold" }}>
                Qty:  {this.state.selectedProduct?.attributes.available_quantity}
              </Typography>
              
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#9399a2', fontFamily:'inter', marginLeft: '20px', fontWeight: "bold" }}>
                Available Qty:  {this.state.selectedProduct?.attributes.available_quantity}
              </Typography>
            </Box>
           {this.isBuyerAccount &&<> <Select defaultValue={1} size="small" sx={{ width: '70px' }}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
              <Button
              variant="contained"
              color="success"

              sx={{ fontSize: '16px',display:'block',textTransform: 'capitalize',width:'197px', marginTop:'36px', padding: '10px', fontWeight: '700' }}
            >
              Add to cart
            </Button> </>}
          </Box>
          </Box>
        </Box>
       
        {/* Dealer Description */}
      <Box sx={{ marginTop: '40px' ,width:'624px'}}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Seller Description
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '16px',fontWeight:400,fontFamily:'inter', marginBottom: '20px' ,color: '#64748B'}}>
        {this.state.selectedProduct?.attributes.description} &nbsp;
        <Typography
          variant="body2"
          component='span'
          sx={{ fontSize: '14px',fontWeight:400,fontFamily:'inter',color: '#007BFF', cursor: 'pointer' }}
        >
          View profile
        </Typography>
        </Typography>
       
      </Box>

      {/* Product Detail */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Product Detail
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' ,fontFamily:'inter',}}>
        {this.state.selectedProduct?.attributes.specifications_attributes?.map(item=>{
              return <Typography variant="body1" sx={{ fontSize: '14px' ,fontFamily:'inter',fontWeight: "bold"}}>
               <span style={{display:'flex' ,justifyContent:'flex-start', width: "auto"}}>
               <span style={{width: "25%"}}> {item.key}</span>  <span style={{marginLeft:'48px',color:'#9399a2'}}>{item.value}</span>
                </span>
                </Typography>
            })}
          
        </Box>
      </Box>
    </Box>
      
        {/* Customizable Area End */}
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100vw",
    backgroundColor: "#ffffffff"
  },

});
// Customizable Area End
