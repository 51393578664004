import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  // Customizable Area End
} from "@mui/material";
  
// Customizable Area Start
import * as Yup from "yup";
import { AddCircleOutline } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { Formik,Form, Field, FieldArray } from "formik";

import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
getPlaceholder = (index:number) => {
  switch (index) {
    case 0:
      return "Age";
    case 1:
      return "Flowering Time";
    default:
      return "Key";
  }
};

  inputStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#BDBDBD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#067B3E",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#757575",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#067B3E",
    },
  };

  productSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    availableQty: Yup.number().required("Available quantity is required"),
    classification: Yup.string().required("Classification is required"),
    type: Yup.string().required("Type is required"),
    price: Yup.number().required("Price is required"),
    images: Yup.array().min(1, "At least one image is required"),
    specifications: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        value: Yup.string().required("Value is required"),
      })
    ),
  });

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      <AppHeader navigtion={this.props.navigation} />
      {this.state.userType === "SellerAccount" && (
          <Box sx={{ p: 3, width: '75%', margin: 'auto' }}>
            <Formik
              initialValues={this.state.addProductState}
              validationSchema={this.productSchema}
              onSubmit={this.handleFormSubmit}
              enableReinitialize
              data-test-id="formik"
            >
              {({ errors, touched, values,resetForm }) => (
                <Form id="product-form">
                  <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 40,
                    width: "95%",
                  }}>
                    <Typography variant="h5">Add product</Typography>
                    <Box>
                      <Button
                        style={{
                          padding: "10px 50px",
                          borderRadius: "25px",
                          height: "44px",
                          border: "1px solid #E2E8F0",
                          background: "#E2E8F0",
                          color: "black",
                        }}
                        variant="outlined"
                        data-test-id="btn-cancel"
                        sx={{ mr: 2 }}
                        onClick={() => resetForm()}
                      >
                        CANCEL
                      </Button>
                      <Button
                        style={{
                          padding: "10px 50px",
                          borderRadius: "25px",
                          height: "44px",
                          border: "1px solid #067B3E",
                          background: "#067B3E",
                          color: "white",
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        data-test-id="btn-submit"
                      >
                        SAVE
                      </Button>
                    </Box>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ paddingTop: 58 }}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        data-test-id="raised-button-file"
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            this.handleImageUpload(Array.from(event.currentTarget.files));
                          }
                        }}
                      />
                      {/* Main big image */}
                      <Box 
                        data-test-id="main-image-container"
                        sx={{ 
                          border: '1px solid #ccc',
                          borderRadius: "10px",
                          p: 2,
                          height: 315,
                          width: 338,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mb: 2,
                          position: 'relative',
                          mt: 2
                        }}
                      >
                        {this.state.addProductState.images[0] ? (
                          <>
                            <img
                              src={URL.createObjectURL(this.state.addProductState.images[0])}
                              alt="Main product"
                              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => this.removeImage(0)}
                              style={{ position: 'absolute', top: 8, right: 8 }}
                              data-test-id="iconButton"
                            >
                              <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                              </svg>
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <label htmlFor="raised-button-file">
                              <Button style={{ background: "#067B3E" }} variant="contained" component="span" data-test-id="btn-upload-image">
                                Upload
                              </Button>
                            </label>
                            <Typography variant="caption" sx={{ mt: 1 }}>
                              Image : Accepted file format .png, .jpeg
                            </Typography>
                          </>
                        )}
                      </Box>
                    
                      {/* 4 smaller image boxes */}
                      <Grid container spacing={1} style={{ width: "calc(100% + 25px)"}}>
                        {[1, 2, 3, 4].map((index) => (
                          <Grid item xs={3} key={index} data-test-id={`small-image-container-${index}`}>
                            <Box
                              sx={{
                                border: '1px solid #ccc',
                                borderRadius: "10px",
                                p: 2,
                                height: 76,
                                width: 76,
                                display: 'flex',
                                padding: 0,
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              {this.state.addProductState.images[index] ? (
                                <>
                                  <img
                                    src={URL.createObjectURL(this.state.addProductState.images[index])}
                                    alt={`Product ${index}`}
                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                                  />
                                  <IconButton
                                    size="small"
                                    onClick={() => this.removeImage(index)}
                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                    data-test-id="iconBtn2"
                                  >
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                                    </svg>
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id={`icon-button-file-${index}`}
                                    multiple
                                    data-test-id="indexIconBtn"
                                    type="file"
                                    onChange={(event) => {
                                      if (event.currentTarget.files) {
                                        this.handleImageUpload(Array.from(event.currentTarget.files));
                                      }
                                    }}
                                  />
                                  <label htmlFor={`icon-button-file-${index}`}>
                                    <IconButton component="span">
                                      <AddCircleOutline />
                                    </IconButton>
                                  </label>
                                </>
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.images && typeof errors.images === 'string' && (
                        <Typography style={{ margin: "10px 0 0 5px"}} color="error">{errors.images}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8} style={{ paddingLeft: 58, paddingTop: 58}}>
                      <Typography variant="subtitle1" gutterBottom>Name</Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        name="name"
                        data-test-id="input-name"
                        placeholder="Enter product name"
                        variant="outlined"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        sx={{ ...this.inputStyle, mb: 2 }}
                      />
                      <Typography variant="subtitle1" gutterBottom>Description</Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        data-test-id="input-description"
                        placeholder="Enter product description"
                        variant="outlined"
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        sx={{ ...this.inputStyle, mb: 2 }}
                      />
                      <Typography variant="subtitle1" gutterBottom>Specifications</Typography>
                      <FieldArray name="specifications" data-test-id="fieldArrayTest">
                        {({ push, remove }) => (
                          <>
                            {values.specifications.map((spec, index) => (
                              <Grid container spacing={2} key={index} sx={{ mb: 2 }} justifyContent="space-between">
                                <Grid item xs={5}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name={`specifications.${index}.key`}
                                    placeholder={this.getPlaceholder(index)}
                                    data-test-id={`input-specification-key-${index}`}
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name={`specifications.${index}.value`}
                                    placeholder="Value"
                                    data-test-id={`input-specification-value-${index}`}
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={2} style={{ maxWidth: "fit-content"}}>
                                  <IconButton data-test-id={`btn-remove-specification-${index}`} onClick={() => remove(index)}>
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                                    </svg>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              onClick={() => push({ key: '', value: '' })}
                              variant="text"
                              data-test-id="btn-add-specification"
                              color="primary"
                              sx={{ mb: 2 }}
                            >
                              Add Another Specification
                            </Button>
                          </>
                        )}
                      </FieldArray>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" gutterBottom>Available Qty</Typography>
                          <Field
                            as={TextField}
                            fullWidth
                            type="number"
                            name="availableQty"
                            data-test-id="input-availableQty"
                            placeholder="Enter available quantity"
                            variant="outlined"
                            error={touched.availableQty && Boolean(errors.availableQty)}
                            helperText={touched.availableQty && errors.availableQty}
                            sx={this.inputStyle}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" gutterBottom>Price</Typography>
                          <Field
                            as={TextField}
                            fullWidth
                            type="number"
                            name="price"
                            data-test-id="input-price"
                            placeholder="Enter price"
                            variant="outlined"
                            error={touched.price && Boolean(errors.price)}
                            helperText={touched.price && errors.price}
                            sx={this.inputStyle}
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="subtitle1" gutterBottom style={{ marginTop: 16}}>Classification</Typography>
                      <Field
                        as={TextField}
                        select
                        fullWidth
                        name="classification"
                        data-test-id="select-classification"
                        placeholder="Select Classification"
                        variant="outlined"
                        error={touched.classification && Boolean(errors.classification)}
                        helperText={touched.classification && errors.classification}
                        sx={this.inputStyle}
                      >
                        <MenuItem value="">Select Classification</MenuItem>
                        <MenuItem value="indica">Indica</MenuItem>
                        <MenuItem value="sativa">Sativa</MenuItem>
                        <MenuItem value="hybrid">Hybrid</MenuItem>
                      </Field>
                      <Typography variant="subtitle1" gutterBottom style={{ marginTop: 16 }}>Type</Typography>
                      <Field
                        as={TextField}
                        select
                        fullWidth
                        name="type"
                        data-test-id="select-type"
                        placeholder="Select type"
                        variant="outlined"
                        error={touched.type && Boolean(errors.type)}
                        helperText={touched.type && errors.type}
                        sx={this.inputStyle}
                      >
                        <MenuItem value="">Select type</MenuItem>
                        <MenuItem value="flower">Flower</MenuItem>
                        <MenuItem value="oil">Oil</MenuItem>
                        <MenuItem value="edible">Edible</MenuItem>
                        <MenuItem value="seeds">Seeds</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
      )}
    </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
