import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";
import { getStorageData } from "../../../framework/src/Utilities";
export const baseConfigJson = require("../../../framework/src/config.js")
export interface Specification {
  id?: number;
  key: string;
  value: string;
  _destroy?: boolean;
}

export interface ProductImage {
  id?: number | null;
  url?: string;
  file?: File;
}

export interface Product {
  id: number;
  type: string;
  attributes: {
    name: string;
    description: string | null;
    product_feature: string | null;
    price: number | string;
    type: string;
    classification: string;
    available_quantity: number;
    images: ProductImage[];
    specifications_attributes: Specification[];
  };
}


export type ProductAttributes = {
  id: number;
  name: string;
  product_feature: string | null;
  stock: number;
  price: number;
  type: string;
  classification: string;
  account_id: number;
  min_quantity: number;
  available_quantity: number;
  images: {
    id: string|null,
    url:string|null
  }[],
  specifications_attributes: any[];
};
export type ProductCardProps = {
  
  id: string;
  type: string;
  attributes: ProductAttributes;
  user:any
  navigtion?:any;
  handelDelete:()=>void
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  Products : any[];
  openDrw:boolean;
  selectedProduct: Product | null;
  isEditModalVisible: boolean;
  editProductState: {
    name: string;
    description: string;
    product_feature: string;
    price: string;
    type: string;
    classification: string;
    availableQty: string;
    specifications: Specification[];
    images: ProductImage[];
  };
  userType: string;

  isBuyer?: any;
  isSpecificationAgeInputVisible:boolean;
  isSpecificationFloweringInputVisible:boolean;
  selectedImageIndex: any;
  selectedImageIndex2: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area Start
  getProductApiCallId: string = "";
  editProductApiCallId: string = "";
  getProductDetailsApiCallId: string = "";
  createNewProductApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      Products:[],
      openDrw:false,
      selectedProduct: null,
      isEditModalVisible: false,
      editProductState: {
        name: "",
        description: "",
        product_feature: "",
        price: "",
        type: "",
        classification: "",
        availableQty: "",
        specifications: [],
        images: [],
      },
      userType: "",
      isBuyer:false,
      isSpecificationAgeInputVisible: true,
      isSpecificationFloweringInputVisible: true,
      selectedImageIndex: 0,
      selectedImageIndex2: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.getLoggendInUserData()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

 

  async receive(from: string, message: Message) {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallID  === this.useDetailsApiCallID){
     
      this.saveLoggedInUserData(responseJson.data)
      this.setState({isBuyer:responseJson.data.attributes?.type === "BuyerAccount"})
   
    }
    if (this.getProductDetailsApiCallId === apiRequestCallID) {      
     
      this.handleGetProductDetailsResponse(responseJson);
    }
    if (apiRequestCallID===this.DeleteProductDetailsApiCallId){
     
        this.getListRequest(localStorage.getItem('authToken')??"");
      
    }
  
    if (this.getProductDetailsApiCallId === apiRequestCallID) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetProductDetailsResponse(responseJson);
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        let params = this.props.navigation.state.params;
        if (params?.data) {
          if (params.data.length > 0) {
            this.setState({ data: params.data });
          } else {
            this.showAlert("Error", "No data Found");
          }
        } else {
          this.setState({
            data: responseJson.data,
          });
        }
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.editProductApiCallId != null &&
      this.editProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      alert(responseJson)
    } 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getProductApiCallId != null &&
    this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleGetProductsResponse(responseJson);
    } 
   
  }

  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteProductDetailsApiCallId:string ="";
  useDetailsApiCallID:string ="";
  getProductDetails = async (productId: number) => {
    const token = await localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  DeleteProductDetails = async (productId: number) => {
    const token = await localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteProductDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userType = async () => {
    const user = JSON.parse(await getStorageData("user"));
    this.setState({userType: user.attributes.type});
  }

  handelDrw=()=>{
    this.setState({openDrw:!this.state.openDrw})
  }

  handleGetProductsResponse = (responseJson: any) => {
    console.log("responseJson", responseJson)
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        arrayHolder: responseJson.data,
        data: responseJson.data,
      });
    } else {
      this.showAlert("Error", "Failed to fetch products");
    }
  };
  handleGetProductDetailsResponse = (responseJson: any) => {
 
      this.setState({
        selectedProduct: responseJson.data,
        editProductState: {
          name: responseJson.data?.attributes?.name || '',
          description: responseJson.data?.attributes?.product_feature || '',
          availableQty: responseJson.data?.attributes?.available_quantity || '',
          price: responseJson.data?.attributes?.price || '',
          classification: responseJson.data?.attributes?.classification || '',
          type: responseJson.data?.attributes?.type || '',
          images: responseJson.data?.attributes?.images || [],
          specifications: responseJson.data?.attributes?.specifications_attributes || [{ key: '', value: '' }],
          product_feature: ""
        }
      });
  
  };
  getLoggendInUserData() {

    const headers = {

      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken')
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.useDetailsApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.UserInfoApi}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.apiMethodTypeGet

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }
  saveLoggedInUserData(responseJson: any) {
 
      localStorage.setItem('user', JSON.stringify(responseJson))
      
    
  }

  handleNavigation = (screenName: string, param: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), String(param));
    runEngine.sendMessage(message.id, message);
}

getQueryParam(key: string) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

closeEditModal = () => {
  this.setState({ isEditModalVisible: false, selectedProduct: null });
};




removeImage = (index: number) => {
  const newImages = this.state.editProductState.images.filter((_, i) => i !== index);
  this.setState(prevState => ({
    editProductState: {
      ...prevState.editProductState,
      images: newImages
    }
  }));
};
handleFormSubmit = async (values: {
  name: string;
  description: string;
  product_feature: string;
  price: string;
  type: string;
  classification: string;
  availableQty: string;
  specifications: Specification[];
  images: any[];
  min_quantity?: string;
  qualification?: string;
  age_value?: string;
  Flowering_time_value?: string;
}) => {  

  try {
    const formData = new FormData();
    formData.append("products[name]", values.name);
    formData.append("products[stock]", values.availableQty.toString());
    formData.append("products[type]", values.type || "");
    formData.append("products[classification]", values.classification || "");
    formData.append("products[min_quantity]", values.min_quantity || "1");
    formData.append("products[qualification]", values.qualification || "1");
    formData.append("products[price]", values.price || "0.00");
    formData.append("products[available_quantity]", values.availableQty.toString());
    formData.append("products[product_feature]", values.description || "");
    
    let startIndex = 0;
    if (values.age_value && this.state.isSpecificationAgeInputVisible) {
      formData.append(`products[specifications_attributes][${startIndex}][key]`, 'Age');
      formData.append(`products[specifications_attributes][${startIndex}][value]`, values.age_value || "");
      startIndex++; 
    }

    if (values.Flowering_time_value && this.state.isSpecificationFloweringInputVisible) {
      formData.append(`products[specifications_attributes][${startIndex}][key]`, 'Flowering time');
      formData.append(`products[specifications_attributes][${startIndex}][value]`, values.Flowering_time_value || "");
      startIndex++; 
    }

    values.specifications.forEach((spec) => {
      formData.append(`products[specifications_attributes][${startIndex}][key]`, spec.key);
      formData.append(`products[specifications_attributes][${startIndex}][value]`, spec.value);
      startIndex++;
    });
    values.images.forEach((image) => {
      if (image?.file) {
        formData.append("products[images][]", image.file);
      }
    });
    const token = await getStorageData("authToken");
    const productId = this.getQueryParam("navigationBarTitleText");    
    const getEndpoint = !productId ? `${configJSON.addProductApiEndPoint}` :`${configJSON.addProductApiEndPoint}/${productId}`
    const response = await fetch(
      `${baseConfigJson.baseURL}${getEndpoint}`,
      {
        method: !productId ? "POST" : "PUT",
        headers: {
          token: token,
        },
        body: formData,
      }
    );

    const result = await response.json();
    if (response.ok) {
      const backToLogin: Message = new Message(getName(MessageEnum.NavigationMessage));
        backToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "Filteritems");
        backToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(backToLogin);
    }
   
  } catch (error) {
    console.error("Error in handleFormSubmit:", error);
  } finally {
    console.log("loading false");
  }
};

handleAddProduct = () => {
  const addproduct: Message = new Message(getName(MessageEnum.NavigationMessage));
  addproduct.addData(getName(MessageEnum.NavigationTargetMessage), "EditProduct");
  addproduct.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(addproduct);
}

  hadelOpenList =()=>{
    this.setState({openDrw: !this.state.openDrw})
  }

  isShowSpecificationAgeInput = () => {
    return !(window.location.search?.length > 0 || !this.state.isSpecificationAgeInputVisible);
  };

  isShowSpecificationFloweringInput = () => {
    return !(window.location.search?.length > 0 || !this.state.isSpecificationFloweringInputVisible);
  }

  handleAgeInput=()=>{
    this.setState({isSpecificationAgeInputVisible:false})
  }

  handleFloweringInput=()=>{
    this.setState({isSpecificationFloweringInputVisible:false})
  }


  setSelectedImageIndex = (index:any) => {
    this.setState({ selectedImageIndex: index });
  };

  // Function to handle navigation to previous image
  handlePrevImage = (imagesLength:any) => {
    this.setState((prevState) => ({
      selectedImageIndex: (prevState.selectedImageIndex - 1 + imagesLength) % imagesLength,
    }));
  };

  // Function to handle navigation to next image
  handleNextImage = (imagesLength:any) => {
    this.setState((prevState) => ({
      selectedImageIndex: (prevState.selectedImageIndex + 1) % imagesLength,
    }));
  };

  handleCancelBtnClick = () => {
    this.props.navigation?.navigate("Filteritems");
  }

  handleBackProductsBtnClick = () => {
    this.props.navigation.goBack();
  }

  getTitle = () => {
    if (window.location.search?.length > 0) {
      return "Edit product"
    } else {
       return "Add product"
    }
  }

  handleNext = () => {
    const { selectedImageIndex2 } = this.state;
    const images = this.state.selectedProduct?.attributes?.images || [];
    this.setState({
      selectedImageIndex2: (selectedImageIndex2 + 1) % images.length,
    });
  };

  handlePrevious = () => {
    const { selectedImageIndex2 } = this.state;
    const images = this.state.selectedProduct?.attributes?.images || [];
    this.setState({
      selectedImageIndex:
        (selectedImageIndex2 - 1 + images.length) % images.length,
    });
  };

  handleThumbnailClick = (index:any) => {
    this.setState({ selectedImageIndex2: index });
  };

  // Customizable Area End
}
